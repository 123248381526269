<template>
  <div class="checkInfo">
    <div class="viewbox" style="margin: 20px;">
        <div class="information">
            <div class="title-icon">基本情况复查</div>
            <el-form :model="basicForm" ref="basicForm" id="basicForm">
                <el-form-item label="1、稳定性" prop="stabilityStatus" :rules="{required:$route.query.isinfo!==true,message:'请选择稳定性',trigger:'change'}">
                    <el-radio-group v-model="basicForm.stabilityStatus" :disabled="$route.query.isinfo&&$route.query.isinfo==true">
                        <el-radio v-for="(item,i) in items" :key="i" :label="item.label">
                            {{item.title}}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="说明原因" class="noteBox" prop="stabilityReason"  :rules="noteRule.stabilityReason" v-show="Number(basicForm.stabilityStatus)>1">
                    <el-input v-model="basicForm.stabilityReason" placeholder="请输入稳定性说明" type="textarea" :rows="3" :disabled="$route.query.isinfo&&$route.query.isinfo==true"></el-input>
                </el-form-item>
                <el-form-item label="2、文化适应性" prop="adaptabilityStatus" :rules="{required:$route.query.isinfo!==true,message:'请选择文化适应性',trigger:'change'}">
                    <el-radio-group v-model="basicForm.adaptabilityStatus" :disabled="$route.query.isinfo&&$route.query.isinfo==true">
                        <el-radio v-for="(item,i) in items" :key="i" :label="item.label">
                            {{item.title}}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="说明原因" class="noteBox" prop="adaptabilityReason" :rules="noteRule.adaptabilityReason" v-show="Number(basicForm.adaptabilityStatus)>1">
                    <el-input v-model="basicForm.adaptabilityReason" placeholder="请输入文化适应性说明" type="textarea" :rows="3" :disabled="$route.query.isinfo&&$route.query.isinfo==true"></el-input>
                </el-form-item>
                <el-form-item label="3、不满或诉求" prop="discontentedStatus" :rules="{required:$route.query.isinfo!==true,message:'请选择不满或诉求',trigger:'change'}">
                    <el-radio-group v-model="basicForm.discontentedStatus" :disabled="$route.query.isinfo&&$route.query.isinfo==true">
                        <el-radio v-for="(item,i) in appeals" :key="i" :label="item.label">
                            {{item.title}}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="说明原因" class="noteBox" prop="discontentedReason" :rules="noteRule.discontentedReason" v-show="basicForm.discontentedStatus==8">
                    <el-input v-model="basicForm.discontentedReason" :disabled="$route.query.isinfo&&$route.query.isinfo==true" placeholder="请输入其他不满或诉求说明" type="textarea" :rows="3"></el-input>
                </el-form-item>
                <el-form-item label="4、成长性" prop="growingStatus" :rules="{required:$route.query.isinfo!==true,message:'请选择成长性',trigger:'change'}">
                    <el-radio-group v-model="basicForm.growingStatus" :disabled="$route.query.isinfo&&$route.query.isinfo==true">
                        <el-radio v-for="(item,i) in items" :key="i" :label="item.label">
                            {{item.title}}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="说明原因" class="noteBox" prop="growingReason" :rules="noteRule.growingReason">
                    <el-input v-model="basicForm.growingReason" :disabled="$route.query.isinfo&&$route.query.isinfo==true" placeholder="请输入成长性说明" type="textarea" :rows="3"></el-input>
                </el-form-item>
                <el-form-item label="5、使用建议" prop="adviseStatus" :rules="{required:$route.query.isinfo!==true,message:'请选择成长性',trigger:'change'}">
                    <el-radio-group v-model="basicForm.adviseStatus" :disabled="$route.query.isinfo&&$route.query.isinfo==true">
                        <el-radio label="0">维持现岗</el-radio>
                        <el-radio label="1">调整岗位</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="新岗位意见" class="noteBox adviseStatus" prop="adviseReason" :rules="noteRule.adviseReason" v-show="basicForm.adviseStatus==1">
                    <el-input v-model="basicForm.adviseReason" :disabled="$route.query.isinfo&&$route.query.isinfo==true" placeholder="请输入新岗位意见" type="textarea" :rows="3"></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
    <div class="viewbox" style="margin: 20px;">
        <div class="information">
            <div class="title-icon">继任者信息</div>
            <el-form :model="successorForm" ref="successorForm" id="successorForm">
                <el-form-item label="继任者情况" prop="haveSuccessor">
                    <el-radio-group v-model="successorForm.haveSuccessor" :disabled="$route.query.isinfo&&$route.query.isinfo==true">
                        <el-radio label="1">有</el-radio>
                        <el-radio label="0">无</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="说明原因" class="noteBox" prop="haveSuccessorReason" :rules="noteRule.haveSuccessorReason" v-show="successorForm.haveSuccessor==0">
                    <el-input v-model="successorForm.haveSuccessorReason" placeholder="请输入说明原因" type="textarea" :rows="3" :disabled="$route.query.isinfo==true"></el-input>
                </el-form-item>
                <el-form-item prop="coreStaffSucceedRequests">
                    <el-collapse v-model="activeNames" v-show="successorForm.haveSuccessor=='1'&&successorForm.coreStaffSucceedRequests&&successorForm.coreStaffSucceedRequests.length>0">
                        <el-collapse-item v-for="(successor,i) in successorForm.coreStaffSucceedRequests" :key="i" class="successorInfo" :name="i">
                            <template slot="title">
                                <p class="successorTitle">{{i==0?'第一继任者':i==1?'第二继任者':i==2?'第三继任者':i==3?'第四继任者':'第五继任者'}}<i class="el-icon-edit-outline" v-show="$route.query.isinfo!=true" @click.stop="centerDialogVisible=true;successorData=successor;type=i"></i></p>
                            </template>
                            <el-form :model="successor" label-width="140px" class="applyForm">
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item label="姓名">
                                                <el-input v-model="successor.succeedStaffName" disabled />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="部门" prop="succeedOrgName">
                                                <el-input v-model="successor.succeedOrgName" disabled></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="入职时间" prop="succeedEntryTime">
                                                <el-date-picker v-model="successor.succeedEntryTime" type="date" disabled></el-date-picker>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="职位" prop="succeedOfficeName">
                                                <el-input v-model="successor.succeedOfficeName" disabled></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item label="核心人力等级" prop="succeedLevelCode">
                                                <el-input v-model="successor.succeedLevelCode" disabled></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="24">
                                            <el-form-item label="核心人力能力">
                                                <el-input v-model="successor.succeedLevelCodeNote" style="width:91.6%" disabled type="textarea" maxlength="999" show-word-limit autosize placeholder="请输入核心人物能力"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="24">
                                            <el-form-item label="继任者状况" prop="succeedStatus">
                                                <el-radio-group v-model="successor.succeedStatus" disabled>
                                                    <el-radio label="1">Ready Now</el-radio>
                                                    <el-radio label="0">Ready Later</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="24">
                                            <el-form-item label="说明原因" prop="succeedReason">
                                                <el-input v-model="successor.succeedReason" style="width:91.6%" autosize type="textarea" placeholder="请输入说明原因" disabled></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-form-item label="近三年绩效考核结果" v-if="successor.activities != null &&successor.activities.length>0&&successor.activities.oneYearPerformanceResponses">
                                        <el-timeline>
                                            <template v-for="(activity, index) in successor.activities">
                                                <el-timeline-item
                                                v-if="activity.coreUserInfoResponse.oneYearPerformanceResponses.length > 0"
                                                :key="index"
                                                color="#409EFF"
                                                :timestamp="activity.assessYear"
                                                :hide-timestamp="true"
                                                >
                                                <template v-if="activity.coreUserInfoResponse.oneYearPerformanceResponses.length > 0">
                                                    <el-collapse :model="activity.show">
                                                    <el-collapse-item name="1" class="collapse-item">
                                                        <template slot="title">
                                                        <div class="textword">{{activity.coreUserInfoResponse.oneYearPerformanceResponses[0].performanceName}}</div>
                                                        <strong>{{activity.coreUserInfoResponse.oneYearPerformanceResponses[0].performanceScoreUser}}</strong>
                                                        </template>
                                                        <div v-if="activity.coreUserInfoResponse.halfYearPerformanceResponses != null && activity.coreUserInfoResponse.halfYearPerformanceResponses.length > 0">
                                                        <div v-for="(item,i1) in activity.coreUserInfoResponse.halfYearPerformanceResponses" :key="i1">
                                                            <div class="textword">{{item.performanceName}}</div>
                                                            <strong>{{item.performanceScoreUser}}</strong>
                                                        </div>
                                                        </div>
                                                        <div v-if="activity.coreUserInfoResponse.quarterPerformanceResponses != null && activity.coreUserInfoResponse.quarterPerformanceResponses.length > 0">
                                                        <div v-for="(item2,i2) in activity.coreUserInfoResponse.quarterPerformanceResponses" :key="i2">
                                                            <div class="textword">{{item2.performanceName}}</div>
                                                            <strong>{{item2.performanceScoreUser}}</strong>
                                                        </div>
                                                        </div>
                                                        <div v-if="activity.coreUserInfoResponse.monthPerformanceResponses != null && activity.coreUserInfoResponse.monthPerformanceResponses.length > 0">
                                                        <div v-for="(item3,i3) in activity.coreUserInfoResponse.monthPerformanceResponses" :key="i3">
                                                            <div class="textword">{{item3.performanceName}}</div>
                                                            <strong>{{item3.performanceScoreUser}}</strong>
                                                        </div>
                                                        </div>
                                                    </el-collapse-item>
                                                    </el-collapse>
                                                </template>
                                            </el-timeline-item>
                                            </template>
                                        </el-timeline>
                                    </el-form-item>
                            </el-form>
                        </el-collapse-item>
                    </el-collapse>
                    <div class="addSuccessor"  v-show="successorForm.haveSuccessor=='1'&&$route.query.isinfo!=true" @click="centerDialogVisible=true;type=null">添加继任者</div>
                </el-form-item>
            </el-form>
        </div>
    </div>
    <el-dialog
        :title="type=='0'?'新增继任者':'编辑继任者'"
        :visible.sync="centerDialogVisible"
        width="40%"
        center>
        <el-form :model="successorData" ref="successorData" class="successorData" inline label-width="90px">
            <el-row>
                <el-col :offset="1" :xl="11" :lg="20">
                    <el-form-item label="继任人" prop="succeedStaffId" :rules="{required:$route.query.isinfo!==true,message:'请选择继任人',trigger:'change'}">
                        <el-select v-model="successorData.succeedStaffId" filterable  placeholder="请选择继任人">
                            <el-option v-for="(item,i) in successors"
                                :key="i"
                                :label="item.name"
                                :value="item.staffId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :offset="1" :xl="11" :lg="20">
                    <el-form-item label="部门">
                        <el-input v-model="successorData.succeedOrgName" disabled></el-input>
                    </el-form-item>
                </el-col>
                <el-col :offset="1" :xl="11" :lg="20">
                    <el-form-item label="职位">
                        <el-input v-model="successorData.succeedOfficeName" disabled></el-input>
                    </el-form-item>
                </el-col>
                <el-col :offset="1" :xl="11" :lg="20">
                    <el-form-item label="入职时间">
                        <el-input v-model="successorData.succeedEntryTime" disabled></el-input>
                    </el-form-item>
                </el-col>
                <el-col :offset="1" :xl="11" :lg="20">
                    <el-form-item label="继任者等级">
                        <el-input v-model="successorData.succeedLevelCode" disabled></el-input>
                    </el-form-item>
                </el-col>
                <el-col :offset="1" :xl="23" :lg="23">
                    <el-form-item label="核心能力">
                        <el-input v-model="successorData.succeedLevelCodeNote" disabled></el-input>
                    </el-form-item>
                </el-col>
                <el-col :offset="1" :xl="23" :lg="23">
                    <el-form-item label="继任状况">
                        <el-radio-group v-model="successorData.succeedStatus">
                            <el-radio label="1">Ready Now</el-radio>
                            <el-radio label="0">Ready Later</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :offset="1" :xl="23" :lg="23">
                    <el-form-item label="说明原因" prop="succeedReason" :rules="{required:$route.query.isinfo!==true,message:'请输入说明原因',trigger:'change'}">
                        <el-input v-model="successorData.succeedReason" type="textarea" placeholder="请输入说明原因"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="centerDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitdata">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import {getcoreData, coreGetUserInfo} from '../../../api/corehuman';
export default {
    props: {
        year: {
            type: String
        },
        resData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        const stabilityNoteRule = (rule, value, callback) => {
            if (Number(this.basicForm.stabilityStatus) > 1 && value === '') {
                callback(new Error('请输入稳定性说明'));
            } else {
                callback();
            }
        };

        const acculturationNoteRule = (rule, value, callback) => {
            if (Number(this.basicForm.adaptabilityStatus) > 1 && value === '') {
                callback(new Error('请输入文化适应性说明'));
            } else {
                callback();
            }
        };

        const grievanceNoteRule = (rule, value, callback) => {
            if (this.basicForm.discontentedStatus == 8 && value === '') {
                callback(new Error('请输入其他不满或诉求说明'));
            } else {
                callback();
            }
        };

        const growthNoteRule = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入成长性说明'));
            } else {
                callback();
            }
        };

        const applicationNoteRule = (rule, value, callback) => {
            if (this.basicForm.adviseStatus == 1 && value === '') {
                callback(new Error('请输入新岗位意见'));
            } else {
                callback();
            }
        };

        const successorNoteRule = (rule, value, callback) => {
            if (this.successorForm.haveSuccessor == 0 && value === '') {
                callback(new Error('请输入说明原因'));
            } else {
                callback();
            }
        };

        return {
            basicForm: {
                stabilityStatus: '',
                stabilityReason: '',
                adaptabilityStatus: '',
                adaptabilityReason: '',
                discontentedStatus: '',
                discontentedReason: '',
                growingStatus: '',
                growingReason: '',
                adviseStatus: '',
                adviseReason: ''
            },
            successorForm: {
                haveSuccessor: '0',
                haveSuccessorReason: '',
                coreStaffSucceedRequests: []
            },
            successorData: {
                succeedStaffId: '',
                succeedOrgName: '',
                succeedOfficeName: '',
                succeedEntryTime: '',
                level: '',
                succeedStatus: '1',
                succeedReason: '',
                activities: []
            },
            noteRule: {
                stabilityReason: {required: this.$route.query.isinfo != true, validator: stabilityNoteRule, trigger: 'blur'},
                adaptabilityReason: {required: this.$route.query.isinfo != true, validator: acculturationNoteRule, trigger: 'blur'},
                discontentedReason: {required: this.$route.query.isinfo != true, validator: grievanceNoteRule, trigger: 'blur'},
                growingReason: {required: this.$route.query.isinfo != true, validator: growthNoteRule, trigger: 'blur'},
                adviseReason: {required: this.$route.query.isinfo != true, validator: applicationNoteRule, trigger: 'blur'},
                haveSuccessorReason: {required: this.$route.query.isinfo != true, validator: successorNoteRule, trigger: 'blur'}
                // coreStaffSucceedRequests:{validator:successorArrRule}
            },
            params: {},
            centerDialogVisible: false,
            type: null,
            items: [{label: '1', title: '高'}, {label: '2', title: '中'}, {label: '3', title: '低'}],
            appeals: [{label: '1', title: '工作内容不满'}, {label: '2', title: '工作地点不满'}, {label: '3', title: '工作待遇不满'}, {label: '4', title: '工作强度不满'}, {label: '5', title: '企业文化不满'}, {label: '6', title: '直线上级不满'}, {label: '7', title: '发展空间不满'}, {label: '8', title: '其他'}],
            activeNames: [0],
            successors: [],
            levels: [],
            options: []
        };
    },
    watch: {
        'successorData.succeedStaffId': {
            handler(val) {
                if (val) {
                    this.successors.map((it) => {
                        if (val == it.staffId) {
                            coreGetUserInfo({staffId: val, year: this.$parent.applyForm.year}).then((res) => {
                                this.successorData.activities = res.responses;
                            });
                            this.successorData.succeedStaffId = it.staffId;
                            this.successorData.succeedStaffName = it.name;
                            this.successorData.succeedOrgName = it.orgName;
                            this.successorData.succeedOfficeName = it.officeName;
                            this.successorData.succeedEntryTime = it.entryTime;
                            this.successorData.succeedLevelCode = it.coreLevel;
                            this.successorData.succeedLevelCodeNote = it.coreLevelNote;
                        }
                    });
                }
            },
            deep: true
        }
    },
    mounted() {
        for (const key in this.resData) {
            for (const key1 in this.basicForm) {
                if (key == key1 && this.resData[key]) {
                    this.basicForm[key1] = this.resData[key];

                }
            }
            for (const key2 in this.successorForm) {
                if (key == key2 && this.resData[key]) {
                    this.successorForm[key2] = this.resData[key];
                }
            }
        }
        getcoreData({year: this.year}).then((res) => {
            if (res._responseStatusCode == 0) {
                delete res._responseStatusCode;
                res.map((it) => {
                    this.successors.push(it);
                });
            }
        });
    },
    methods: {
        submitdata() {
            this.$refs['successorData'].validate((validate) => {
                if (validate) {
                    if (this.type === null) {
                        this.successorForm.coreStaffSucceedRequests.push(JSON.parse(JSON.stringify(this.successorData)));
                    } else {
                        this.successorForm.coreStaffSucceedRequests[this.type] = JSON.parse(JSON.stringify(this.successorData));
                    }
                    this.$refs.successorData.clearValidate();
                    this.$refs.successorData.resetFields();
                    this.centerDialogVisible = false;
                }
            });
        },
        toSubmit() {
            if (this.successorForm.haveSuccessor == '1' && this.successorForm.coreStaffSucceedRequests.length == 0) {
                this.$message.warning('请选择继任者');
                return;
            } if (this.successorForm.haveSuccessor == '0' && !this.successorForm.haveSuccessorReason) {
                this.$message.warning('请填写原因');
                return;
            }
            this.params = {};
            this.$refs.basicForm.validate((valid) => {
                if (valid) {
                this.$refs.successorForm.validate((valid1) => {
                    if (valid1) {
                        this.params = Object.assign(this.basicForm, this.successorForm);
                    }
                });
                }
            });
        },
        toSave() {
            this.params = Object.assign(this.basicForm, this.successorForm);
        }
    }
};
</script>
<style lang="scss">
    .checkInfo {
        .viewbox {
            #basicForm,#successorForm  {
                padding: 20px 2%;
                .el-form-item {
                    margin-bottom: 20px;
                }
                .el-form-item__error {
                    left:px;
                }
                >.el-form-item>.el-form-item__label,
                .successorTitle{
                    font: bold 16px Arial;
                    width: 100%;
                    text-align: left;
                }
                .el-radio-group {
                    margin: 10px 0 0 22px;
                    width: 60%;
                    .el-radio {
                        margin: 10px;
                        min-width: 64px;
                        text-align: left;
                    }
                }
                .noteBox {
                    padding-left: 15px;
                    .el-form-item__label {
                        // text-align: center;
                        // width: 90px;
                        font-weight: normal;
                    }
                    .el-form-item__error {
                        left:0px;
                    }
                    .el-textarea {
                        width: 100%;
                        vertical-align: text-top;
                    }
                }
            }
            #successorForm {
                .addSuccessor {
                    border: 1px dashed #409EFF;
                    color: #409EFF;
                    border-radius: 2px;
                    font-size: 16px;
                    padding:0 25px;
                    text-align: center;
                    margin-top: 20px;
                    cursor: pointer;
                }
                .successorInfo {
                    .el-icon-edit-outline {
                        margin-left: 10px;
                        cursor: pointer;
                    }
                    .applyForm {
                        padding: 20px 40px;
                        .el-form-item {
                            margin:15px 0;
                            .el-radio-group {
                                width: 100%;
                                margin:0
                            }
                        }
                    }
                }
            }
        }
        .successorData {
            .el-form-item {
                width: 100%;
                .el-select,.el-input {
                    width: 190px;
                }
                .el-textarea {
                    width: 550px;
                }
                @media only screen and (max-width: 1750px) {
                    .el-select,.el-input{
                        width: 300px;
                    }
                    .el-textarea {
                        width: 450px;
                    }
                }
                @media only screen and (max-width: 1536px) {
                    .el-select,.el-input,.el-textarea {
                        width: 330px;
                    }
                }
                @media only screen and (max-width: 1308px) {
                    .el-select,.el-input,.el-textarea {
                        width: 250px;
                    }
                }
            }
        }
    }
</style>
