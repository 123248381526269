<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-17 20:19:43
 * @LastEditTime: 2020-04-17 20:29:31
 -->
<template>
    <div class="apprvalLbox">
      <el-timeline>
        <el-timeline-item color="#409EFF" size="large">
          <div class="approvalConChild">
            <div class="approvalConChild_head">
              <span>审批人</span>
              <p style="color: #ccc;font-weight:500">{{approveStaffs.length}}人审批</p>
            </div>
            <div class="approvalConChild_container">
              <div
                @click="_openDialog(1,'approveStaffs')"
                class="block"
                style="cursor: pointer;"
                v-show="approveStaffs.length > 8"
              >
                <div>
                  <div style="text-align: center;">
                    <img src="../../../assets/approval/查看全部@3x.png" style="width: 38px;" alt />
                  </div>
                  <p class="approvalConChild_container_name">查看全部</p>
                </div>
                <i class="el-icon-arrow-right"></i>
              </div>
              <div
                class="block"
                v-for="(item,index) in approveStaffs"
                :key="index"
                v-show="index <= 8"
              >
                <div>
                  <div style="position: relative;padding: 0 10px;">
                    <el-button
                      @click="_deleteCCorStaff('approveStaffs',index)"
                      v-if="$route.query.isinfo=='check'||$route.query.isinfo=='edit'||!$route.query.isinfo"
                      style="background-color: #181F25;color: #fff;"
                      size="mini"
                      class="delete"
                      icon="el-icon-close"
                      circle
                    ></el-button>
                    <el-avatar :size="38" :src="item.avatarUrl"></el-avatar>
                  </div>
                  <p class="approvalConChild_container_name">{{item.userName || item.staffName}}</p>
                </div>
                <i class="el-icon-arrow-right" v-if="index != approveStaffs.length-1"></i>
              </div>
            </div>
            <div v-if="$route.query.isinfo=='check'||$route.query.isinfo=='edit'||!$route.query.isinfo">
              <el-button
                class="approvalConChild_add"
                icon="el-icon-plus"
                circle
                @click="_addCCorStaff(1)"
              ></el-button>
            </div>
          </div>
        </el-timeline-item>
        <el-timeline-item color="#409EFF" size="large">
          <div class="approvalConChild">
            <div class="approvalConChild_head">
              <span>抄送人</span>
              <p style="color: #ccc;font-weight:500">抄送{{ccStaffs.length}}人</p>
            </div>
            <div class="approvalConChild_container">
              <div
                @click="_openDialog(2,'ccStaffs')"
                class="block"
                style="cursor: pointer;"
                v-show="ccStaffs.length > 8"
              >
                <div>
                  <div style="text-align: center;">
                    <img src="../../../assets/approval/查看全部@3x.png" style="width: 38px;" alt />
                  </div>
                  <p class="approvalConChild_container_name">查看全部</p>
                </div>
                <i class="el-icon-arrow-right"></i>
              </div>
              <div class="block" v-for="(item,index) in ccStaffs" :key="index" v-show="index <= 8">
                <div>
                  <div style="position: relative;padding: 0 10px;">
                    <el-button
                      @click="_deleteCCorStaff('ccStaffs',index)"
                      style="background-color: #181F25;color: #fff;"
                      size="mini"
                      class="delete"
                      v-if="$route.query.isinfo=='check'||$route.query.isinfo=='edit'||!$route.query.isinfo"
                      icon="el-icon-close"
                      circle
                    ></el-button>
                    <el-avatar :size="38" :src="item.avatarUrl"></el-avatar>
                  </div>
                  <p class="approvalConChild_container_name">{{item.userName || item.staffName}}</p>
                </div>
                <i class="el-icon-plus" v-if="index != ccStaffs.length-1"></i>
              </div>
            </div>
            <div>
              <el-button
                class="approvalConChild_add"
                icon="el-icon-plus"
                circle
                v-if="$route.query.isinfo=='check'||$route.query.isinfo=='edit'||!$route.query.isinfo"
                @click="_addCCorStaff(2)"
              ></el-button>
            </div>
          </div>
        </el-timeline-item>
      </el-timeline>
      <el-dialog
        append-to-body
        top="10px"
        center
        width="65%"
        :title="ccStatusType == 1 ? '选择审批人' : '选择抄送人'"
        :visible.sync="ccStatus"
      >
        <div style="height:80vh;">
          <table-employees
            v-if="ccStatus"
            :selectStatus="true"
            @confirm="_confirmSelect"
            :show="ccStatus"
            :filter-params="{}"
            @cancel="ccStatus=false;"
          ></table-employees>
        </div>
      </el-dialog>
      <el-dialog append-to-body top="10px" :visible.sync="ccDialogStatus" class="morye">
        <span slot="title" class="dialog-title">{{ccStatusType == 1 ? '审批人' : '抄送人'}}</span>
        <div style="height:80vh;">
          <div
            style="margin-bottom: 10px;"
          >{{ccStatusType == 1 ? `${cpStaffs.length}人依次审批` : `向${cpStaffs.length}人抄送`}}</div>
          <el-row>
            <div
              v-for="(item,index) in cpStaffs"
              :key="index"
              style="display:inline-block;margin-bottom: 20px;"
            >
              <div class="block">
                <div>
                  <div style="position: relative;padding: 0 10px;">
                    <el-button
                      @click="_deleteCCorStaff('cpStaffs',index) "
                      v-if="modifyStatus == 1 || ccStatusType != 1&&$route.query.isinfo=='check'"
                      style="background-color: #181F25;color: #fff;"
                      size="mini"
                      class="delete"
                      icon="el-icon-close"
                      circle
                    ></el-button>
                    <el-avatar :size="38" :src="item.avatarUrl"></el-avatar>
                  </div>
                  <p class="approvalConChild_container_name">{{item.userName || item.staffName}}</p>
                </div>
                <i
                  :class="ccStatusType == 1 ? 'el-icon-arrow-right' : 'el-icon-plus'"
                  style="margin-top: -8px;"
                ></i>
              </div>
            </div>
            <div
              style="display:inline-block;width: 58px;text-align: center;position:relative;top:-10px;"
            >
              <el-button
                class="approvalConChild_add"
                icon="el-icon-plus"
                circle
                v-if="$route.query.isinfo=='check'"
                @click="_addCCorStaff(ccStatusType)"
              ></el-button>
            </div>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer" style="text-align: center;">
          <el-button @click="ccDialogStatus = false;" size="medium">取消</el-button>
          <el-button type="primary" size="medium" @click="_saveCpStaffs">保存</el-button>
        </span>
      </el-dialog>
    </div>
</template>
<script>
import TableEmployees from '@/views/staffSystem/components/table-employees';
export default {
  components: { TableEmployees },
  props: {
    approvalProcessList: {
      type: Array,
      default: () => []
    },
    ccList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      approveStaffs: [], // 新增审批集合
      ccStaffs: [], // 新增抄送人集合
      cpStaffs: [], // 弹窗审批或抄送人列表
      ccStatusType: '',
      ccStatus: false, //
      modifyStatus: 0, // 是否能删除增加审批人;1能，0不能
      ccDialogStatus: false
    };
  },
  watch: {
    approvalProcessList: {
      handler(val) {
        if (val.length > 0) {
          this.approveStaffs = val;
        }
      }
    },
    ccList: {
      handler(val) {
        if (val.length > 0) {
          this.ccStaffs = val;
        }
      }
    }
  },
  mounted() {
    if (this.approvalProcessList) {this.approveStaffs = this.approvalProcessList;}
    if (this.ccList) {this.ccStaffs = this.ccList;}

  },
  methods: {
    _confirmSelect(value) {
      // 抄送人或审批人
      if (!value[0]) {
        this.$message.error('未选择员工');
        return false;
      }
      const _obj = value[0];

      if (this.ccDialogStatus) {
        this.cpStaffs.push(_obj);
      } else {
        this.ccStatusType == 1
          ? this.approveStaffs.push(_obj)
          : this.ccStaffs.push(_obj);
      }
    },
    _addCCorStaff(type) {
      // 添加抄送人或审批人
      this.ccStatusType = type;
      this.ccStatus = true;
    },
    _openDialog(type, listName) {
      // 打开查看全部
      this.ccStatusType = type;
      this.cpStaffs = JSON.parse(JSON.stringify(this[listName]));
      this.ccDialogStatus = true;
    },
    _deleteCCorStaff(listName, index) {
      // 删除抄送人或审批人
      if (
        (listName == 'approveStaffs' && this[listName].length - 1 == 0) ||
        (listName == 'cpStaffs' &&
          this.ccStatusType == 1 &&
          this[listName].length - 1 == 0)
      ) {
        this.$message.error('审批人至少一个');
        return;
      }
      this[listName].splice(index, 1);
    },
    _saveCpStaffs() {
      this.ccStatusType == 1 ? (this.approveStaffs = [...this.cpStaffs]) : (this.ccStaffs = [...this.cpStaffs]);
      this.ccDialogStatus = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.approvalConChild {
  flex: 1;
  display: flex;
  flex-direction: row;
  // border-bottom: 1px solid #eaedf1;
  padding-bottom: 15px;
  .approvalConChild_head {
    span{
      margin-bottom: 10px;
      display: block;
    }
  }
  .approvalConChild_container {
    flex: 1;
    width: 100%;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    flex-wrap: nowrap;
    white-space: nowrap;
  }
}
.morye,
.approvalConChild_container {
  .block {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    .approvalConChild_container_name {
      line-height: 14px;
      color: #c8c8c8;
      font-size: 14px;
    }
    .delete {
      position: absolute;
      top: -1px;
      right: 0;
      padding: 2px;
    }
    p {
      text-align: center;
    }
    i.el-icon-arrow-right {
      color: #c5cdda;
      font-size: 14px;
      margin-top: -30px;
    }
    i.el-icon-plus {
      color: #c5cdda;
      font-size: 14px;
      margin-top: -30px;
    }
  }
}
.approvalConChild_add {
  border: 1px dashed #409eff;
  color: #409eff;
}
</style>
