<template>
	<div class="applyInfo">
		<div class="app-container">
			<div class="titlebox">
				<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
				<div class="title">
					{{
						$route.name == 'CoreApply'
							? '核心人力申报'
							: $route.name == 'checkInfo'
							? '核心人力复查'
							: $route.name == 'applyInfo'
							? '核心人力详情'
							: $route.name == 'corehumanAdjust'
							? '核心人力调整'
							: '核心人力报表'
					}}
				</div>
			</div>
			<div class="viewbox">
				<div class="information">
					<div class="title-icon">基本信息</div>
					<el-form :model="applyForm" ref="applyForm" label-width="140px" class="applyForm">
						<el-row>
							<el-col :span="12">
								<el-form-item
									label="申报名称"
									prop="declareName"
									v-if="$route.name != 'applyInfo' && $route.name != 'corehumanAdjust'"
									:rules="{ required: $route.query.isinfo !== true, message: '请选择所申报名称', trigger: 'change' }"
								>
									<el-select
										v-model="applyForm.declareName"
										placeholder="请选择所申报名称"
										:disabled="$route.name != 'CoreApply' || $route.query.isinfo === true"
									>
										<el-option v-for="(item, i) in options" :key="i" :value="item.coreName"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item
									label="申报名称"
									prop="declareName"
									v-else-if="$route.name == 'corehumanAdjust'"
									:rules="{ required: $route.query.isinfo !== true, message: '请选择所申报名称', trigger: 'change' }"
								>
									<el-select
										v-model="applyForm.declareName"
										placeholder="请选择所申报名称"
										:disabled="$route.query.isinfo === true"
									>
										<el-option v-for="(item, i) in options" :key="i" :value="item.coreName"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item
									label="申报名称"
									prop="declareName"
									v-else
									:rules="{ required: $route.query.isinfo !== true, message: '请选择所申报名称', trigger: 'change' }"
								>
									<el-select
										v-model="applyForm.declareName"
										placeholder="请选择所申报名称"
										:disabled="$route.query.isinfo === true"
									>
										<el-option v-for="(item, i) in options" :key="i" :value="item.coreName"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="10" :offset="2">
								<el-form-item label="申报时间" prop="year">
									<el-input v-model="applyForm.year" disabled></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item
									label="核心人力"
									v-if="
										($route.name != 'applyInfo' && !applyForm.outCompany && applyForm.declareName) || $route.name == 'CoreApply'
									"
									prop="staffId"
									:rules="{ required: $route.query.isinfo !== true, message: '请选择所申报核心人力', trigger: 'change' }"
								>
									<el-select
										v-model="applyForm.staffId"
										placeholder="请选择所申报核心人力"
										:disabled="$route.name != 'CoreApply' || $route.query.isinfo === true"
										filterable
									>
										<el-option v-for="item in dutyUsers" :key="item.value" :label="item.label" :value="item.value"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item
									label="核心人力"
									v-else-if="$route.name == 'applyInfo'"
									prop="staffId"
									:rules="{ required: $route.query.isinfo !== true, message: '请选择所申报核心人力', trigger: 'change' }"
								>
									<el-select
										v-model="applyForm.staffId"
										placeholder="请选择所申报核心人力"
										:disabled="$route.query.isinfo === true"
										filterable
									>
										<el-option v-for="item in dutyUsers" :key="item.value" :label="item.label" :value="item.value"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item
									label="核心人力"
									v-if="
										($route.name == 'checkInfo' || $route.name == 'reportInfo' || $route.name == 'corehumanAdjust') &&
										applyForm.outCompany
									"
								>
									<el-input disabled :value="`${applyForm.staffName}(已离职)`" style="width: 60%"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="10" :offset="2">
								<el-form-item label="部门" prop="orgName">
									<el-input v-model="applyForm.orgName" disabled></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="职位" prop="officeName">
									<el-input v-model="applyForm.officeName" disabled style="width: 60%"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="10" :offset="2">
								<el-form-item label="入职时间" prop="entryTime">
									<el-date-picker v-model="applyForm.entryTime" type="date" disabled></el-date-picker>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="12">
								<el-form-item
									:label="$route.name == 'corehumanAdjust' ? '当前等级' : '核心人力等级'"
									v-if="$route.name != 'applyInfo'"
									prop="beforeCoreLevelId"
									:rules="{ required: $route.query.isinfo !== true, message: '请选择核心人力等级', trigger: 'change' }"
								>
									<el-select
										v-model="applyForm.beforeCoreLevelId"
										placeholder="请选择核心人力等级"
										:disabled="$route.name != 'CoreApply' || $route.query.isinfo === true"
									>
										<el-option v-for="(item, i) in levels" :key="i" :label="item.levelName" :value="item.id"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item
									:label="$route.name == 'corehumanAdjust' ? '当前等级' : '核心人力等级'"
									v-else
									prop="beforeCoreLevelId"
									:rules="{ required: $route.query.isinfo !== true, message: '请选择核心人力等级', trigger: 'change' }"
								>
									<el-select
										v-model="applyForm.beforeCoreLevelId"
										placeholder="请选择核心人力等级"
										:disabled="$route.query.isinfo === true"
									>
										<el-option v-for="(item, i) in levels" :key="i" :label="item.levelName" :value="item.id"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="10" :offset="2" v-if="$route.name == 'corehumanAdjust'">
								<el-form-item
									label="变动等级"
									prop="afterCoreLevelId"
									:rules="{ required: $route.query.isinfo !== true, message: '请选择核心人力等级', trigger: 'change' }"
								>
									<el-select
										v-model="applyForm.afterCoreLevelId"
										placeholder="请选择核心人力等级"
										style="width: 80%"
										:disabled="($route.name != 'CoreApply' && $route.name != 'corehumanAdjust') || $route.query.isinfo === true"
									>
										<el-option label="GND" value="gnd"></el-option>
										<el-option v-for="(item, i) in levels" :key="i" :label="item.levelName" :value="item.id"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="23">
								<el-form-item
									label="核心人力能力"
									prop="coreLevelNote"
									v-if="$route.name != 'applyInfo'"
									:rules="{ required: $route.query.isinfo !== true, message: '请输入核心人物能力', trigger: 'blur' }"
								>
									<el-input
										v-model="applyForm.coreLevelNote"
										:disabled="$route.name != 'CoreApply' || $route.query.isinfo === true"
										type="textarea"
										style="width: 97.5%"
										maxlength="999"
										show-word-limit
										autosize
										placeholder="请输入核心人物能力"
									></el-input>
								</el-form-item>
								<el-form-item
									label="核心人力能力"
									prop="coreLevelNote"
									v-else
									:rules="{ required: $route.query.isinfo !== true, message: '请输入核心人物能力', trigger: 'blur' }"
								>
									<el-input
										v-model="applyForm.coreLevelNote"
										:disabled="$route.query.isinfo === true"
										type="textarea"
										maxlength="999"
										show-word-limit
										:rows="5"
										placeholder="请输入核心人物能力"
									></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<!--<p class="border-line" style="width:100%" v-if="applyForm.responses != null &&applyForm.responses.length>0"></p>!-->
						<el-form-item
							label="近三年绩效考核结果"
							v-if="
								applyForm.responses != null &&
								applyForm.responses.length > 0 &&
								(applyForm.responses[0].coreUserInfoResponse.oneYearPerformanceResponses.length > 0 ||
									applyForm.responses[1].coreUserInfoResponse.oneYearPerformanceResponses.length > 0 ||
									applyForm.responses[2].coreUserInfoResponse.oneYearPerformanceResponses.length > 0)
							"
						>
							<el-timeline>
								<template v-for="(activity, index) in applyForm.responses">
									<el-timeline-item
										v-if="activity.coreUserInfoResponse.oneYearPerformanceResponses.length > 0"
										:key="index"
										color="#409EFF"
										:timestamp="activity.assessYear"
										:hide-timestamp="true"
									>
										<template v-if="activity.coreUserInfoResponse.oneYearPerformanceResponses.length > 0">
											<el-collapse v-model="collapseActive">
												<el-collapse-item name="1" class="collapse-item">
													<template slot="title">
														<div class="textword">
															{{ activity.coreUserInfoResponse.oneYearPerformanceResponses[0].performanceName }}
														</div>
														<strong>{{
															activity.coreUserInfoResponse.oneYearPerformanceResponses[0].performanceScoreUser
														}}</strong>
													</template>
													<div
														v-if="
															activity.coreUserInfoResponse.halfYearPerformanceResponses != null &&
															activity.coreUserInfoResponse.halfYearPerformanceResponses.length > 0
														"
													>
														<div
															v-for="(item, i1) in activity.coreUserInfoResponse.halfYearPerformanceResponses"
															:key="i1"
														>
															<div class="textword">{{ item.performanceName }}</div>
															<strong>{{ item.performanceScoreUser }}</strong>
														</div>
													</div>
													<div
														v-if="
															activity.coreUserInfoResponse.quarterPerformanceResponses != null &&
															activity.coreUserInfoResponse.quarterPerformanceResponses.length > 0
														"
													>
														<div
															v-for="(item2, i2) in activity.coreUserInfoResponse.quarterPerformanceResponses"
															:key="i2"
														>
															<div class="textword">{{ item2.performanceName }}</div>
															<strong>{{ item2.performanceScoreUser }}</strong>
														</div>
													</div>
													<div
														v-if="
															activity.coreUserInfoResponse.monthPerformanceResponses != null &&
															activity.coreUserInfoResponse.monthPerformanceResponses.length > 0
														"
													>
														<div v-for="(item3, i3) in activity.coreUserInfoResponse.monthPerformanceResponses" :key="i3">
															<div class="textword">{{ item3.performanceName }}</div>
															<strong>{{ item3.performanceScoreUser }}</strong>
														</div>
													</div>
												</el-collapse-item>
											</el-collapse>
										</template>
									</el-timeline-item>
								</template>
							</el-timeline>
						</el-form-item>
						<!-- <i class="iconfont icon-z044"  v-show="item3.assessCycle=='4'"></i> -->
						<el-form-item label="核心人力等级记录" v-if="$route.name == 'reportInfo'">
							<el-timeline>
								<el-timeline-item v-for="(activity, index) in applyForm.coreRecordResponses" :key="index" color="#409EFF">
									<div class="textword" style="width: 60px">{{ activity.year }}年</div>
									<strong>{{ activity.coreLevel }}</strong>
								</el-timeline-item>
							</el-timeline>
						</el-form-item>
					</el-form>
				</div>
			</div>
			<humancheck v-if="$route.name == 'checkInfo' && applyForm.year" :resData="resData" :year="applyForm.year" ref="humancheck"></humancheck>
			<div
				class="viewbox"
				:style="!applyForm.responses || applyForm.responses.length == 0 ? 'padding-bottom:20px;margin-top:20px' : 'padding-bottom:20px'"
				v-if="$route.query.isinfo != true"
			>
				<div class="information" style="margin-bottom: 20px">
					<div class="title-icon">审批流程</div>
					<ccindex
						ref="apprvalL"
						v-if="$route.query.isinfo != true"
						:approvalProcessList="applyForm.approvalProcessList"
						:ccList="applyForm.ccList"
					></ccindex>
				</div>
				<el-form :model="applyForm" ref="form2" style="padding: 0 1%" label-width="100px" v-if="$route.name != 'reportInfo'">
					<el-form-item
						label="提交时间"
						prop="submitDateTime"
						v-if="$route.name != 'applyInfo'"
						:rules="{ required: $route.query.isinfo !== true, message: '请选择提交时间', trigger: 'change' }"
					>
						<el-date-picker
							v-model="applyForm.submitDateTime"
							value-format="yyyy-MM-dd HH:mm:ss"
							format="yyyy-MM-dd HH:mm"
							type="datetime"
							:disabled="
								($route.query.isinfo != 'edit' &&
									$route.query.isinfo != 'check' &&
									$route.name != 'CoreApply' &&
									$route.name != 'corehumanAdjust') ||
								(applyForm.reviewStatus == '1' &&
									applyForm.lastdataStatus == '2' &&
									$route.query.approvalStatus &&
									$route.query.approvalStatus != '3')
							"
							placeholder="选择日期时间"
						/>
					</el-form-item>
					<el-form-item
						label="提交时间"
						prop="submitDateTime"
						v-else
						:rules="{ required: $route.query.isinfo !== true, message: '请选择提交时间', trigger: 'change' }"
					>
						<el-date-picker
							v-model="applyForm.submitDateTime"
							value-format="yyyy-MM-dd HH:mm:ss"
							format="yyyy-MM-dd HH:mm"
							type="datetime"
							:disabled="$route.query.isinfo == true"
							placeholder="选择日期时间"
						/>
					</el-form-item>
					<el-form-item
						label="状态"
						prop="dataStatus"
						v-if="$route.name == 'checkInfo' && (applyForm.reviewStatus != '1' || applyForm.lastdataStatus != '2')"
						:rules="{ required: $route.query.isinfo !== true, message: '请选择状态', trigger: 'change' }"
					>
						<el-radio-group v-model="applyForm.dataStatus" :disabled="$route.query.isinfo != 'edit' && $route.query.isinfo != 'check'">
							<el-radio label="1">草稿</el-radio>
							<el-radio label="2">提交</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
				<div style="text-align: center" v-if="$route.name != 'reportInfo' && $route.query.isinfo != true">
					<el-button @click="$router.back()">取消</el-button>
					<el-button type="primary" :loading="loading" @click="submit">提交</el-button>
				</div>
			</div>
			<!-- <div class="viewbox" v-if="applyForm.reviewStatus!=0&&applyForm.approvalProcessList.length>0&&$route.query.isinfo==true&&$route.name!='checkInfo'"> -->
			<div class="viewbox" v-if="applyForm.approvalProcessList.length > 0 && $route.query.showApproval == true">
				<div class="information">
					<div class="title-icon">审批结果</div>
					<el-form :model="applyForm" ref="applyForm" label-width="140px" class="applyForm">
						<el-form-item>
							<el-timeline>
								<el-timeline-item
									v-for="(result, index) in applyForm.approvalProcessList"
									:key="index"
									color="#409EFF"
									class="reviewresult"
									:timestamp="result.approvalStatus == 3 || result.approvalStatus == 2 ? '' : result.time.split(' ')[0]"
								>
									<div class="view">
										<p>
											<span :class="[result.status == '3' ? 'active' : '']"
												>{{ result.userName }} {{ result.status == '3' ? '(离职)' : '' }}</span
											>
											<el-button
												class="statusbtn"
												:type="result.approvalStatus == '1' ? 'success' : result.approvalStatus == '0' ? 'danger' : 'primary'"
												size="mini"
												>{{ result.approvalStatusName }}</el-button
											>
										</p>
										<p class="reason" style="line-height: 20px">
											<span v-if="result.approvalOpinion">{{ result.approvalStatusName }}说明：</span>
											<span class="con-text" v-if="result.approvalOpinion">
												<span>{{ result.approvalOpinion }}</span>
											</span>
										</p>
										<div v-if="result.annexContent && JSON.parse(result.annexContent).length > 0">
											<div>附件:</div>
											<div
												class="docContainer"
												style="width: 100%"
												v-for="(file, index) in makeFileList(result.annexContent)"
												:key="index"
											>
												<div class="docContent">
													<img src="/static/images/fj.png" style="width: 40px; height: 40px" />
													<div style="width: auto; height: 55px; margin-left: 5px">
														<!-- <el-tooltip :content="file.name"
                                        placement="top"> -->
														<span class="fileName">{{ file.name }}</span>
														<!-- </el-tooltip> -->
														<div style="text-align: left; transform: translateY(-10px)">
															<el-button
																v-show="isImageFileName(file.url)"
																type="text"
																size="small"
																class="media-print__hidden"
																@click="picPreview(file.url)"
																>预览</el-button
															>
															<a
																v-show="!isImageFileName(file.url)"
																class="media-print__hidden"
																style="color: #409eff; margin-right: 10px"
																target="_blank"
																:href="'http://view.officeapps.live.com/op/view.aspx?src=' + file.url"
																>预览</a
															>
															<el-button type="text" class="media-print__hidden" @click="downLoadFile(file.url)"
																>下载</el-button
															>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</el-timeline-item>
							</el-timeline>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="dialogVisible2">
			<img width="100%" :src="dialogImageUrl" alt="" />
		</el-dialog>
	</div>
</template>
<script>
import humancheck from './component/humancheck';
import ccindex from './component/ccindex';
import {
	coreDeclarelSave,
	getNearlyInfo,
	coreGetUserInfo,
	coreConfiglList,
	coreHumanDetail,
	coreChangeSave,
	coreReviewSave
} from '../../api/corehuman';
import { selectPrincipallist } from '../../api/common';
import { getProcessDetail } from '@/api/audit';
import { timestampToTime } from '../../utils/util';
import { getApprovalType } from '../../api/staffSystem';
import { isImageFileName } from '@/utils/util.js';
export default {
	components: {
		humancheck,
		ccindex
	},
	watch: {
		'applyForm.declareName': {
			handler(val) {
				if (val) {
					this.options.map((it) => {
						if (it.coreName == val) {
							this.applyForm.year = it.coreYear;
						}
					});
				}
			}
		},
		'applyForm.staffId': {
			handler(val) {
				if (val) {
					coreGetUserInfo({ staffId: this.applyForm.staffId, year: this.applyForm.year }).then((res) => {
						if (res._responseStatusCode == 0) {
							for (const key in res) {
								for (const key1 in this.applyForm) {
									if (key == key1) {
										this.applyForm[key1] = res[key];
									}
								}
							}
						}
					});
				}
			}
		}
	},
	data() {
		return {
			applyForm: {
				coreManpowerApprovalId: this.$route.params.coreManpowerApprovalId || '',
				declareName: '',
				year: '',
				outCompany: true,
				staffName: '',
				coreManpowerId: this.$route.query.coreManpowerId,
				staffId: '',
				orgName: '',
				status: '1',
				reviewStatus: '',
				officeName: '',
				entryTime: '',
				beforeCoreLevelId: '',
				coreLevelNote: '',
				responses: null,
				afterCoreLevelId: '',
				submitDateTime: new Date(),
				approvalProcessList: [],
				ccList: [],
				coreRecordResponses: [],
				dataStatus: '1',
				lastdataStatus: '',
				changeStatus: '',
				type: this.$route.query.type
			},
			resData: null,
			loading: false,
			dutyUsers: [],
			levels: [],
			options: [],
			dialogImageUrl: '',
			dialogVisible2: false,
			collapseActive: []
		};
	},
	methods: {
		isImageFileName,
		makeFileList(json) {
			try {
				const fileList = JSON.parse(json);

				return fileList;
			} catch (err) {
				return [];
			}
		},
		downLoadFile(url) {
			window.open(url);
		},
		picPreview(url) {
			this.dialogImageUrl = url;
			this.dialogVisible2 = true;
		},
		submit() {
			const approvalStaffIds = [];
			const ccList = [];

			this.$refs.apprvalL.approveStaffs.map((it) => {
				approvalStaffIds.push(it.staffId);
			});
			this.$refs.apprvalL.ccStaffs.map((it) => {
				ccList.push(it.staffId);
			});
			if (approvalStaffIds.length == 0) {
				this.$message.warning('请至少选择一个审批人');
				return false;
			}
			if (this.$route.name == 'CoreApply' || this.$route.name == 'applyInfo') {
				this.$refs['applyForm'].validate(async (valid) => {
					if (valid) {
						const params = {
							...this.applyForm,
							submitDateTime:
								typeof this.applyForm.submitDateTime === 'object'
									? timestampToTime(this.applyForm.submitDateTime.getTime())
									: this.applyForm.submitDateTime,
							threeYearsPerformance: JSON.stringify(this.applyForm.responses),
							approvalStaffIds,
							ccList
						};

						this.loading = true;
						coreDeclarelSave(params)
							.then((res) => {
								this.loading = false;
								if (res._responseStatusCode == 0) {
									this.$router.push({ name: 'CoreAudit' });
									this.$message.success('核心人力申报已提交');
								}
							})
							.catch(() => {
								this.loading = false;
							});
					}
				});
			} else if (this.$route.name == 'checkInfo') {
				if (this.applyForm.dataStatus == '1') {
					this.$refs.humancheck.toSave();
				} else {
					this.$refs.humancheck.toSubmit();
				}
				// console.log(params)
				// console.log(Object.entries(this.$refs.humancheck.params).length);
				// setTimeout(()=>{
				if (Object.entries(this.$refs.humancheck.params).length > 0) {
					const params = {
						...this.$refs.humancheck.params,
						...this.applyForm,
						approvalStaffIds,
						type: this.$route.query.type ? this.$route.query.type : this.$route.query.isinfo === 'edit' ? '1' : '',
						ccList,
						threeYearsPerformance: JSON.stringify(this.applyForm.responses),
						submitDateTime:
							typeof this.applyForm.submitDateTime === 'object'
								? timestampToTime(this.applyForm.submitDateTime.getTime())
								: this.applyForm.submitDateTime
					};

					this.loading = true;
					coreReviewSave(params)
						.then((res) => {
							this.loading = false;
							if (res._responseStatusCode == 0) {
								this.$message.success('核心人力复查提交成功');
								this.$router.push({ name: 'CoreReview' });
							}
						})
						.catch(() => {
							this.loading = false;
						});
				}
				// },1000)
			} else if (this.$route.name == 'corehumanAdjust') {
				const params = {
					...this.applyForm,
					approvalStaffIds,
					threeYearsPerformance: JSON.stringify(this.applyForm.responses),
					ccList,
					type: this.$route.query.isinfo == 'edit' ? '1' : '0',
					afterCoreLevelId: this.applyForm.afterCoreLevelId == 'gnd' ? this.applyForm.beforeCoreLevelId : this.applyForm.afterCoreLevelId,
					submitDateTime:
						typeof this.applyForm.submitDateTime === 'object'
							? timestampToTime(this.applyForm.submitDateTime.getTime())
							: this.applyForm.submitDateTime
				};

				this.loading = true;
				coreChangeSave(params)
					.then((res) => {
						this.loading = false;
						if (res._responseStatusCode === 0) {
							this.$message.success('核心人力调整提交成功');
							this.$router.push({ name: 'CoreReview' });
						}
					})
					.catch(() => {
						this.loading = false;
					});
			}
		},
		async getApprovalType() {
			let approvalId;

			getApprovalType().then((res) => {
				if (res._responseStatusCode === 0) {
					res.map((it) => {
						if (it.approvalName == '核心人力') {
							approvalId = it.approvalId;
						}
					});
					getProcessDetail({ request: { approvalInfoId: approvalId } }).then((res) => {
						if (this.$refs.apprvalL) {
							this.$refs.apprvalL.modifyStatus = res[0].modifyStatus ? res[0].modifyStatus : 0;
							const _list = [];

							for (const key in res) {
								if (key != '_responseStatusCode') {
									_list.push(res[key]);
								}
							}
							this.$refs.apprvalL.approveStaffs = _list;
						}
					});
				}
			});
		},
		getCodeHumanInfo() {
			coreHumanDetail({
				coreManpowerApprovalId: this.$route.params.coreManpowerApprovalId,
				readStatus: this.$route.query.cctoinfo ? '1' : ''
			}).then((res) => {
				if (res._responseStatusCode == 0) {
					for (const key in res) {
						for (const key1 in this.applyForm) {
							if (key == key1 && res[key]) {
								this.applyForm[key] = res[key1];
							}
						}
					}
					this.dutyUsers.map((it) => {
						if (this.applyForm.staffId == it.value) {
							console.log(it);
							this.applyForm.outCompany = false;
						}
					});
					if (this.$route.query.isinfo !== true) {
						this.applyForm.submitDateTime = new Date();
					}
					if (this.$route.name == 'reportInfo' && this.applyForm.afterCoreLevelId) {
						this.applyForm.beforeCoreLevelId = this.applyForm.afterCoreLevelId;
						this.applyForm.afterCoreLevelId = '';
					}
					this.applyForm.lastdataStatus = res.dataStatus;
					this.resData = res;
				}
			});
		}
	},
	mounted() {
		selectPrincipallist({}).then((res) => {
			this.dutyUsers = [];
			res.map((it) => {
				this.dutyUsers.push({
					label: `${it.staffName} ${it.officeName ? `(${it.officeName})` : ''}`,
					value: String(it.staffId)
				});
			});
		});
		coreConfiglList({}).then((res) => {
			if (res._responseStatusCode == 0) {
				this.levels = res.configListDtos;
			}
		});
		getNearlyInfo({}).then((res) => {
			if (res._responseStatusCode == 0) {
				delete res._responseStatusCode;
				this.options = res;
			}
		});
		// this.$nextTick(()=>{
		//   this.getApprovalType();
		// })
		if (this.$route.name != 'CoreApply') {
			this.getCodeHumanInfo();
		}
	}
};
</script>

<style lang="scss">
.applyInfo {
	font-weight: bold;
	.app-container {
		color: #484848;
		padding: 0;
		background-color: #f6f7fb;
		.lastone {
			margin: 0px 0 24px;
		}
		.titlebox {
			text-align: center;
			line-height: 17px;
			background: #fff;
			font: bold 18px/25px 'Microsoft YaHei';
			padding: 14px 40px;
			> .el-button {
				float: left;
				color: #484848;
				transform: translateY(-8px);
				font-size: 16px;
			}
			text-align: center;
			line-height: 17px;
			font: bold 18px/25px 'Microsoft YaHei';
			margin: 20px;
		}
		.viewbox {
			margin: 0 20px;
			background: #ffffff;
			min-height: 300px;
			padding: 20px 50px 0px;
			.information {
				.apprvalLbox {
					transform: translateY(30px);
					padding: 0 2%;
				}
				// height: 500px;
				.approval {
					display: flex;
					flex-direction: row;
					.approvalBar {
						padding: 20px 0;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						height: 180px;
						margin-right: 20px;
						.approvalBarBall {
							width: 20px;
							height: 20px;
							border-radius: 50%;
							background-color: #ebebeb;
						}
						.approvalBarbar {
							flex: 1;
							width: 1px;
							background-color: #ebebeb;
						}
					}
					.approvalCon {
						display: flex;
						flex-direction: column;
						flex: 1;
						.approvalConBar {
							background-color: #ebebeb;
							height: 1px;
							margin-bottom: 10px;
						}
						.approvalConChild {
							flex: 1;
							display: flex;
							flex-direction: row;
							// .approvalConChild_head {
							// }
							.approvalConChild_container {
								height: 95px;
								margin-right: 20px;
								flex: 1;
								display: flex;
								flex-direction: row;
								justify-content: flex-end;
								align-items: flex-start;
								.block {
									display: flex;
									flex-direction: row;
									align-items: center;
									position: relative;
									.approvalConChild_container_name {
										line-height: 14px;
									}
									.delete {
										position: absolute;
										top: -1px;
										right: -6px;
										padding: 2px;
									}
									p {
										text-align: center;
									}
									i.el-icon-arrow-right {
										font-size: 24px;
										margin: -30px 10px 0 10px;
									}
									i.el-icon-plus {
										font-size: 24px;
										margin: -30px 10px 0 10px;
									}
								}
							}
						}
					}
				}
				label {
					width: 120px;
					// font-size: 16px;
					text-align: right;
					font-weight: normal;
					display: inline-block;
				}
				.title-icon {
					font-size: 18px;
					display: flex;
					align-items: center;
					&:before {
						display: inline-block;
						width: 8px;
						height: 22px;
						transform: translateY(1px);
						background: #498df0;
						content: ' ';
						border-radius: 5px;
						vertical-align: middle;
						margin-right: 10px;
					}
				}
				.applyForm {
					padding: 20px 1% 0;
					.el-select {
						width: 60%;
					}
					.el-form-item__content > .el-input {
						width: 80%;
					}
					.el-timeline {
						margin-top: 13px;
						.el-timeline-item__wrapper {
							top: -13px;
							.textword {
								display: inline-block;
								width: 180px;
								vertical-align: middle;
							}
							strong {
								font-size: 20px;
								display: inline-block;
								line-height: 40px;
								width: 2%;
								margin-left: 8px;
								max-width: 3%;
								transform: translateY(1px);
								vertical-align: middle;
							}
							i {
								margin-left: 20px;
								display: inline-block;
								cursor: pointer;
								margin-top: 8px;
							}
						}
					}
					.active {
						color: #f00;
					}
					.reviewresult {
						font-weight: normal;
						.el-timeline-item__content {
							.reason {
								font-size: 12px;
								color: #c8c8c8;
							}
							.view {
								width: 96%;
								> p {
									height: auto;
									.statusbtn {
										margin: 0 15px;
									}
								}
							}
							> p {
								height: auto;
								.statusbtn {
									margin: 0 15px;
								}
							}
						}
						.corehumanfile {
							margin-left: 20px;
						}
						.corehumanfile + .corehumanfile {
							display: block;
							margin-left: 52px;
						}
						.el-timeline-item__timestamp {
							position: absolute;
							top: 5px;
							left: -90px;
						}
					}
				}
				.reportInfo {
					padding: 20px 10% 0;
					.el-timeline {
						margin-top: 13px;
						.el-timeline-item__wrapper {
							top: -13px;
							.textword {
								display: inline-block;
								transform: translateY(1px);
								vertical-align: middle;
							}
							strong {
								font-size: 20px;
								display: inline-block;
								line-height: 40px;
								width: 2%;
								margin-left: 8px;
								max-width: 3%;
								transform: translateY(1px);
								vertical-align: middle;
							}
							i {
								margin-left: 20px;
								display: inline-block;
								cursor: pointer;
								transform: translateY(5px);
							}
						}
					}
				}
			}
		}
	}
	label {
		font-weight: normal;
	}
}
</style>
<style lang="scss" scoped>
::v-deep.el-collapse-item__arrow.el-icon-arrow-right {
	margin-bottom: 8px;
}
::v-deep.el-collapse-item__header {
	border: 0;
	height: 38px;
}
::v-deep.el-collapse {
	border-top: 0;
}
.docContainer {
	.docContent {
		display: flex;
		align-items: center;
		margin-left: 10px;
		min-width: 120px;
	}
	.fileName {
		// display: block;
		font-size: 12px;
		display: inline-block;
		transform: translateY(-5px);
		text-align: left;
		// width: 300px;
		// overflow: hidden;
		// text-overflow: ellipsis;
		// white-space: nowrap;
	}
	.media-print__hidden {
		font-size: 12px;
		display: inline-block;
		transform: translateY(-8px);
	}
}
</style>
